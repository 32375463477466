import React from "react";

class About extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section id="about" class="about">
          <div class="container">
            <p />
            <div class="row">
              <div class="col-lg-6">
                <img
                  src="assets/img/about.jpg"
                  class="img-fluid"
                  alt="Photo by Dose Media on Unsplash"
                />
                <br />
                <br />
                <br />
              </div>
              <div class="col-lg-6 pt-4 pt-lg-0">
                <h4>
                  Der Markt verändert sich von Tag zu Tag - und wir uns mit ihm
                </h4>
                <p>
                  Gemo-Trade AG stützt sich bei der Finanzplanung auf einen
                  detaillierten, mehrstufigen Ansatz, der folgende Elemente
                  berücksichtigt:
                </p>
                <ul>
                  <li>
                    <i class="bx bx-check-double"></i>Analyse von
                    Vermögenswerten und Verpflichtungen
                  </li>
                  <li>
                    <i class="bx bx-check-double"></i>Analyse der Einnahmen und
                    Ausgaben
                  </li>
                  <li>
                    <i class="bx bx-check-double"></i>Festlegen der persönlichen
                    und finanziellen Ziele
                  </li>
                  <li>
                    <i class="bx bx-check-double"></i>Entwicklung der
                    Finanzstrategie
                  </li>
                  <li>
                    <i class="bx bx-check-double"></i>Entwicklung der geeigneter
                    Massnahmen
                  </li>
                  <li>
                    <i class="bx bx-check-double"></i>Project Management
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default About;
