import React from "react";

class WhatWeDo extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section id="what-we-do" class="what-we-do">
          <div class="container">
            <div class="section-title">
              <h2>Ein Unternehmen mit Zukunft</h2>
              <p>
                Unsere Services umfassen die Vermittlung von
                Finanzdienstleistungen sowie den Handel mit Immobilien
              </p>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default WhatWeDo;
