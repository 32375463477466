import React from "react";

class Hero extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section
          id="hero"
          class="d-flex flex-column justify-content-center align-items-center"
        >
          <div class="container text-center text-md-left" data-aos="fade-up">
            <h1>
              <span>Gemo</span>-Trade AG
            </h1>
            <h2>Spezialisten für Immobilien und Finanzen</h2>
            <a href="#about" class="btn-get-started scrollto">
              Company Profile
            </a>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Hero;
