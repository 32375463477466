import React from "react";

class Contact extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section id="contact" class="contact section-bg">
          <div class="container">
            <div class="section-title">
              <h2>Koordinaten</h2>
              <p>
                Unsere Kundenberater sind für Sie da.
                <br />
                Zögern sie nicht mit uns Kontakt aufzunehmen.
              </p>
            </div>

            <div class="row mt-5 justify-content-center">
              <div class="col-lg-10">
                <div class="info-wrap">
                  <div class="row">
                    <div class="col-lg-4 info">
                      <i class="icofont-google-map"></i>
                      <h4>Standort</h4>
                      <p>Pelikanweg 2, CH-4054 Basel</p>
                    </div>

                    <div class="col-lg-4 info mt-4 mt-lg-0">
                      <i class="icofont-envelope"></i>
                      <h4>Email</h4>
                      <p>info&#64;gemotrade.ch</p>
                    </div>

                    <div class="col-lg-4 info mt-4 mt-lg-0">
                      <i class="icofont-world"></i>
                      <h4>Website</h4>
                      <p>www.gemotrade.ch</p>
                    </div>

                    {/*div class="col-lg-4 info mt-4 mt-lg-0">
                      <i class="icofont-phone"></i>
                      <h4>Telefon:</h4>
                      <p>+41 61 271 58 85</p>
                      </div>*/}
                  </div>
                </div>
              </div>
            </div>

            {/* 
            <div class="row mt-5 justify-content-center">
              <div class="col-lg-10">
                <div class="php-email-form">
                  <div class="form-row">
                    <div class="col-md-6 form-group">
                      <input
                        type="text"
                        name="name"
                        class="form-control"
                        id="name"
                        placeholder="Your Name"
                        data-rule="minlen:4"
                        data-msg="Please enter at least 4 chars"
                      />
                      <div class="validate"></div>
                    </div>
                    <div class="col-md-6 form-group">
                      <input
                        type="email"
                        class="form-control"
                        name="email"
                        id="email"
                        placeholder="Your Email"
                        data-rule="email"
                        data-msg="Please enter a valid email"
                      />
                      <div class="validate"></div>
                    </div>
                  </div>
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control"
                      name="subject"
                      id="subject"
                      placeholder="Subject"
                      data-rule="minlen:4"
                      data-msg="Please enter at least 8 chars of subject"
                    />
                    <div class="validate"></div>
                  </div>
                  <div class="form-group">
                    <textarea
                      class="form-control"
                      name="message"
                      rows="5"
                      data-rule="required"
                      data-msg="Please write something for us"
                      placeholder="Message"
                    ></textarea>
                    <div class="validate"></div>
                  </div>
                  <div class="mb-3">
                    <div class="loading">Loading</div>
                    <div class="error-message"></div>
                    <div class="sent-message">
                      Your message has been sent. Thank you!
                    </div>
                  </div>
                  <div class="text-center">
                    <button type="submit">Send Message</button>
                  </div>
                </div>
              </div>
            </div>
            */}
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Contact;
