import React from "react";
import Header from "./components/Header";
import Hero from "./components/Hero";
import WhatWeDo from "./components/WhatWeDo";
import About from "./components/About";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import GoToTop from "./components/GoToTop";

function App() {
  return (
    <div className="App">
      <Header />
      <Hero />
      <main id="main">
        <WhatWeDo />
        <About />
        <Contact />
      </main>
      <Footer />
      <GoToTop />
    </div>
  );
}

export default App;
