import React from "react";

class GoToTop extends React.Component {
  render() {
    return (
      <React.Fragment>
        <a href="#" class="back-to-top">
          <i class="icofont-simple-up"></i>
        </a>
      </React.Fragment>
    );
  }
}

export default GoToTop;
