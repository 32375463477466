import React from "react";

class Footer extends React.Component {
  render() {
    return (
      <React.Fragment>
        <footer id="footer">
          <div class="footer-top">
            <div class="container">
              <div class="row">
                <div class="col-lg-3 col-md-6 footer-contact">
                  <h3>Gemo-Trade AG</h3>
                  <p>
                    Pelikanweg 2<br />
                    CH-4054 Basel
                    <br />
                    Switzerland
                    <br />
                    <br />
                    {/*Phone:+41 61 271 58 85
                    <br />*/}
                    Email: info&#64;gemotrade.ch
                    <br />
                  </p>
                </div>

                <div class="col-lg-4 col-md-6 footer-newsletter">
                  {/* 
                  <h4>Join Our Newsletter</h4>
                  <p>
                    Tamen quem nulla quae legam multos aute sint culpa legam
                    noster magna
                  </p>
                  */}
                </div>

                <div class="col-lg-2 col-md-6 footer-links">
                  {/* 
                  <h4>Sitemap</h4>
                  <ul>
                    <li>
                      <i class="bx bx-chevron-right"></i> <a href="#">Home</a>
                    </li>
                    <li>
                      <i class="bx bx-chevron-right"></i>{" "}
                      <a href="#">Dienstleistungen</a>
                    </li>
                    <li>
                      <i class="bx bx-chevron-right"></i>{" "}
                      <a href="#">Kontakt</a>
                    </li>
                  </ul>
                  */}
                </div>

                <div class="col-lg-3 col-md-6 footer-links">
                  <img
                    src="assets/img/stock-exchange.png"
                    class="img-fluid"
                    alt="Stock exchange."
                  />

                  {/* 

                  <h4>Dienstleistungen</h4>
                  <ul>
                    <li>
                      <i class="bx bx-chevron-right"></i>
                      Web Design
                    </li>
                  </ul>
                  */}
                </div>
              </div>
            </div>
          </div>

          <div class="container d-md-flex py-4">
            <div class="mr-md-auto text-center text-md-left">
              <div class="copyright">
                &copy;&nbsp;Copyright {new Date().getFullYear()} Gemo-Trade AG.
                Alle Rechte vorbehalten
              </div>
              {/* <div class="credits">
                Written with ♥ and React.js by Pascal Cantaluppi
              </div> */}
            </div>
            <div class="social-links text-center text-md-right pt-3 pt-md-0">
              <a href="#" class="twitter">
                <i class="bx bxl-twitter"></i>
              </a>
              <a href="#" class="facebook">
                <i class="bx bxl-facebook"></i>
              </a>
              <a href="#" class="linkedin">
                <i class="bx bxl-linkedin"></i>
              </a>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

export default Footer;
